<template>
    <modal
        :saveAction="save"
        :title="$t(title)"
        :id="id"
        :actionButtons="false"
        size="xl"
        :closeButton="true"
        scrollable
        @shown="shown"
    >
        <div class="row" style="height:75vh; overflow-y:auto">
            <div class="col">
                <nav
                    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
                    style="position: relative; border-width: 0;"
                >
                    <ul class="navbar-nav mb-md-3">
                        <li class="nav-item" v-for="(navItem, index) in navs" :key="index">
                            <a
                                class="nav-link"
                                href="#"
                                :class="{ active: categoryLocal == navItem.value }"
                                @click.prevent="categoryLocal=navItem.value"
                            >{{ $t(navItem.label) }}</a>
                        </li>
                    </ul>
                </nav>
                <!-- <hr />
                <button class="btn btn-white" @click="openModal({id:'cameraModal', multiple:true})">
                    <span class="fe fe-camera"></span>
                    {{$t('Take a photo')}}
                </button> -->
            </div>
            <div class="col-12 col-md-10">
                <uploader :params="{category:categoryLocal}"></uploader>
                <div class="row">
                    <div class="col small text-muted text-center p-5" v-if="images.length==0">{{$t('Nothing yet')}}</div>
                    <div class="col-2" v-else v-for="(image, index) in images" :key="index">
                        <div class="card">
                            <img :src="image.preview" class="card-img-top" v-if="image.preview" />
                            <img :src="image.url" class="card-img-top" v-else />

                            <div class="card-body p-2">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <button class="btn btn-white btn-sm" @click="remove(image._id)">
                                            <span class="fe fe-trash"></span>
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-white btn-sm" @click="use(image)">
                                            <span class="fe fe-check"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- / .card-body -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <camera-modal :model="model" id="cameraModal"></camera-modal>
    </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from 'vuex';
import { modalMixins } from '@/mixins/modal.js'
import Uploader from '@/components/Uploader.vue';
import CameraModal from '@/components/CameraModal.vue';

export default {
    name: 'media-modal',
    props: ['id', 'action', 'category', 'data'],
    mixins: [modalMixins],
    components: {
        Modal,
        Uploader,
        CameraModal
    },
    computed: {
        ...mapGetters('media', {
            findMediaInStore: 'find'
        }),
        images() {
            return this.findMediaInStore(this.params).data;
        },
    },
    watch: {
        categoryLocal(newValue) {
            this.params.query.category = newValue;
            this.fetchMedia();
        }
    },
    data() {
        return {
            categoryLocal: null,
            model: 'another',
            navs: [
                { value: 'profile', label: 'Profile' },
                { value: null, label: 'Others' },
            ],
            errors: null,
            hideForm: false,
            title: 'Media manager',
            params: {
                query: {
                    customerId: null,
                    tags: { $in: ['Images'] },
                    $sort: { createdAt: -1 },
                    category: null

                }
            },
        }
    },
    created() {
        this.fetchMedia();
    },


    methods: {
        ...mapActions('media', ['find', 'remove', 'create']),

        fetchMedia() {
            // console.log(this.params);
            // this.params.query = { customerId: null, tags: { $in: ['Images', 'others'] } };
            this.find(this.params);
        },
        shown() {
            this.categoryLocal = this.category;
        },
        use(image) {
            this.$root.$emit('bv::hide::modal', this.id);
            this.action(image, this.data);
        },
        save() {
            this.errors = null;
            this.hideForm = true;
        }
    },

}
</script>
<style lang="scss" scoped>

</style>