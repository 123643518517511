<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar m-0"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="#" class="navbar-brand">Dashboard</a>
                        </li>
                        <li
                            class="breadcrumb-item active d-flex justify-content-center align-items-center"
                            aria-current="page"
                        >
                            Appearance
                        </li>
                    </ol>
                </nav>
            </div>
        </nav>

        <div class="container">

            <div class="header">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                        <h6 class="header-pretitle">
                            Choose the theme
                        </h6>
                        <h1 class="header-title">
                            Appearance
                        </h1>
                        </div>
                        <div class="col-auto">
                        </div>
                    </div>
                </div>
            </div>

            <div style="height: calc( 100vh - 200px ); overflow-x: scroll;">
              <div class="row">
                <div class="col-12 mb-5">
                    <div class="card h-100">
                        <div class="card-body d-flex align-items-center">
                            <div class="col">
                                <div class="avatar avatar-xxl" v-if="Object.keys(localUser.avatar).length !== 0">
                                    <img :src="localUser.avatar.preview" class="avatar-img rounded-circle">
                                </div>
                                <div class="avatar avatar-xxl text-center" v-else>
                                    <img src="@/assets/source/Profile.svg" class="w-75" />
                                </div>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-lg btn-primary mr-4" @click="openModal({id:'mediaModal', multiple:true})">Pick an Image</button>
                                <button class="btn btn-lg btn-secondary" @click="removeAvatar">Remove Image</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-auto m-auto" v-for="(theme, index) in themes" :key="index">
                    <div class="card" @click="selectTheme(theme)" :class="{'bg-warning': localUser.themeId == theme._id}">
                      <div class="card-body p-2">
                        <img v-if="theme.coverImage" :src="theme.coverImage.url" alt="" style="min-width: 100%; width: 250px;">
                        <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 100%; min-width: 100%; width: 250px;">
                          <img src="@/assets/source/no-image.svg" />
                        </div>
                        <div class="p-1 text-center bg-white">
                          <span class="text-secondary">{{ theme.name }}</span>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <media-modal
            id="mediaModal"
            category="profile"
            :action="useImage"
            size="full"
            :data="model"
        ></media-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { modalMixins } from '@/mixins/modal.js'
import MediaModal from "@/components/MediaModal.vue";

export default {
    mixins: [modalMixins],
    components: {
        MediaModal
    },

    data() {
        return {
            model: {},
            params: {
                query: {
                    $limit: 1000,
                    $sort: { createdAt: -1 },
                    isPublic: true,
                },
            },
            localUser: {
              avatar: {},
            },
        };
    },
    computed: {
        ...mapState("auth", {
            user: "payload",
        }),
        ...mapState('themes', {
            areThemesLoading: 'isFindPending',
        }),
        ...mapGetters('themes', {
            findThemesInStore: 'find',
        }),
        themes() {
            return this.findThemesInStore(this.params).data;
        },
        
    },
    created() {
        this.find(this.params);
        this.getUser([this.user.userId, { query: { byusername: false } }]).then(res => {
            this.localUser = res;
        })
    },

    methods: {
        ...mapActions("themes", ['create', 'find', 'patch', 'remove']),
        ...mapActions("users", {
            'patchUser': 'patch',
            'getUser': 'get'
        }),
        useImage(image) {
            this.localUser.avatar = image;
            this.patchUser([ this.user.userId, { avatarId: image._id } ])
            .then((res) => {
                // this.$toasted.global.success({
                //     message: this.$t("Avatar has been updated"),
                // });
            })
            .catch((error) => {
                this.$toasted.global.error({
                    message: `${error.message}`,
                });
                console.log(error)
            });
        },
        removeAvatar() {
            this.patchUser([ this.user.userId, {$unset: { avatarId: '' }} ])
            .then((res) => {
                // this.$toasted.global.success({
                //     message: this.$t("Avatar has been removed"),
                // });
                this.localUser.avatar = false
            })
            .catch((error) => {
                this.$toasted.global.error({
                    message: `${error.message}`,
                });
                console.log(error)
            });
        },
        selectTheme(theme) {
            this.patchUser([ this.user.userId, { themeId: theme._id } ])
            .then((res) => {
                // this.$toasted.global.success({
                //     message: this.$t("Default theme selected"),
                // });
            })
            .catch((error) => {
                this.$toasted.global.error({
                    message: `${error.message}`,
                });
                console.log(error)
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.image-placeholder {
    height: 214.66px;
    width: 214.66px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
</style>